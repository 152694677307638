export const initMenu = () => {
  const navBtn = document.querySelector('.nav__toggle');
  const nav = document.querySelector('.nav');
  const linkMenu = document.querySelectorAll('.nav__item a[href^="#"]');
  // const body = document.querySelector('body');

  const onMenuOpened = () => {
    nav.classList.add('nav--open');
    document.body.classList.add('scroll-lock');
  };

  const onMenuClosed = () => {
    nav.classList.remove('nav--open');
    document.body.classList.remove('scroll-lock');
  };

  const onMenuEsc = (evt) => {
    if (evt.keyCode === 27) {
      evt.preventDefault();
      nav.classList.remove('nav--open');
      document.body.classList.remove('scroll-lock');
    }
  };

  if (navBtn) {
    navBtn.addEventListener('click', (evt) => {
      evt.preventDefault();
      if (nav.classList.contains('nav--open')) {
        onMenuClosed();
        document.removeEventListener('keydown', onMenuEsc);
      } else {
        onMenuOpened();
        document.addEventListener('keydown', onMenuEsc);
      }
    });
  }

  // Скролл к якорю
  if (linkMenu) {

    for (const anchor of linkMenu) {
      anchor.addEventListener('click', (e) => {
        // e.preventDefault();
        // const blockID = anchor.getAttribute('href');
        // if (document.querySelector(blockID)) {
        if (nav.classList.contains('nav--open')) {
          onMenuClosed();
        }
        // document.querySelector(blockID).scrollIntoView({
        // behavior: 'smooth',
        // block: 'start',
        // });
        // }
      });
    }
  }

  // Фиксация меню при скролле
  window.addEventListener('scroll', function (evt) {
    if (pageYOffset > 10) {
      nav.classList.add('nav--scroll');
    } else {
      nav.classList.remove('nav--scroll');
    }
  })
}
