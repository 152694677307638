export const initShowWorks = function () {
  const btnShowMore = document.querySelector('.works__btn-more');
  let worksVisib = document.querySelector('.works__amount-num');
  let worksAll = document.querySelector('.works__amount-all');
  const array = Array.from(document.querySelector('.works__list').children);
  let itemsShow = 3;

  //  Удалить если вывод карточек через admin панель
  worksVisib.textContent = itemsShow;
  worksAll.textContent = array.length;

  if (btnShowMore) {
    btnShowMore.addEventListener('click', function (evt) {
      evt.preventDefault();

      array.forEach(function (item) {
        item.classList.remove('is-visible');
      })

      if (!btnShowMore.closest('.is-collapse')) {
        itemsShow += 3;
      } else {
        itemsShow = 3;
      }
      const visItems = array.slice(0, itemsShow);

      visItems.forEach(function (item) {
        item.classList.add('is-visible');
      })

      if (visItems.length === array.length) {
        btnShowMore.textContent = 'Свернуть все';
        btnShowMore.classList.add('is-collapse');
      } else {
        btnShowMore.textContent = 'Показать еще';
        btnShowMore.classList.remove('is-collapse');
      }

      worksVisib.textContent = itemsShow;
      worksAll.textContent = array.length;
    });
  }
};
